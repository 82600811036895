import { Group, Image, Menu, UnstyledButton, createStyles } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { useEffect, useState } from "react";
import images from "./images/images";

export const country = [
	{ value: "RO", image: images.romanian32, label: "ROMANIA" },
	//moldova
	{ value: "MD", image: images.moldova32, label: "MOLDOVA" },
	{ value: "HU", image: images.hungarian32, label: "MAGYARORSZÁG" },
	{ value: "PL", image: images.polish32, label: "POLSKA" },
	{ value: "NL", image: images.dutch32, label: "NEDERLAND" },
	// { value: "FR", image: images.french32, label: "FRANCE" },
	// { value: "DE", image: images.german32, label: "DEUTSCHLAND" },
	// { value: "TR", image: images.turkish32, label: "TÜRKIYE" },
	{ value: "BG", image: images.bulgarian32, label: "БЪЛГАРИЯ" },
	// { value: "SK", image: images.slovak32, label: "SLOVENSKO" },
];
export const timezone = [
	{ value: "Europe/Bucharest", image: images.romanian32, label: "Europe/Bucharest" },
	{ value: "Europe/Chisinau", image: images.moldova32, label: "Europe/Chisinau" },
	{ value: "Europe/Budapest", image: images.hungarian32, label: "Europe/Budapest" },
	{ value: "Europe/Warsaw", image: images.polish32, label: "Europe/Warsaw" },
	{ value: "Europe/Amsterdam", image: images.dutch32, label: "Europe/Amsterdam" },
	// { value: "Europe/Paris", image: images.french32, label: "Europe/Paris" },
	// { value: "Europe/Berlin", image: images.german32, label: "Europe/Berlin" },
	// { value: "Europe/Ankara", image: images.turkish32, label: "Europe/Ankara" },
	{ value: "Europe/Sofia", image: images.bulgarian32, label: "Europe/Sofia" },
	// { value: "Europe/Bratislava", image: images.slovak32, label: "Europe/Bratislava" },
];
export const companyLanguage = [
	{ value: "ro", image: images.romanian32, label: "ROMÂNĂ" },
	{ value: "hu", image: images.hungarian32, label: "MAGYAR" },
	{ value: "en", image: images.english32, label: "ENGLISH" },
	{ value: "pl", image: images.polish32, label: "POLSKI" },
	// { value: "nl", image: images.dutch32, label: "NEDERLANDS" },
	// { value: "fr", image: images.french32, label: "FRANÇAISE" },
	// { value: "de", image: images.german32, label: "DEUTSCH" },
	// { value: "tr", image: images.turkish32, label: "TÜRKÇE" },
	{ value: "bg", image: images.bulgarian32, label: "БЪЛГАРСКИ" },
	// { value: "sk", image: images.slovak32, label: "SLOVENČINA" },
];
export const currency = [
	{ value: "RON", image: images.romanian32, label: "RON" },
	{ value: "MDL", image: images.moldova32, label: "MDL" },
	{ value: "HUF", image: images.hungarian32, label: "HUF" },
	{ value: "PLN", image: images.polish32, label: "PLN" },
	{ value: "EUR", image: images.dutch32, label: "EUR" },
	// { value: "EUR", image: images.french32, label: "EUR" },
	// { value: "EUR", image: images.german32, label: "EUR" },
	// { value: "TRL", image: images.turkish32, label: "TRL" },
	{ value: "BGN", image: images.bulgarian32, label: "BGN" },
	// { value: "EUR", image: images.slovak32, label: "EUR" },
];

export const countryPrefix = [
	{ value: "+40", image: images.romanian32, label: "+40" },
	{ value: "+373", image: images.moldova32, label: "+373" },
	{ value: "+36", image: images.hungarian32, label: "+36" },
	{ value: "+48", image: images.polish32, label: "+48" },
	{ value: "+31", image: images.dutch32, label: "+31" },

	// { value: "+33", image: images.french32, label: "+33" },
	// { value: "+49", image: images.german32, label: "+49" },
	// { value: "+90", image: images.turkish32, label: "+90" },
	{ value: "+359", image: images.bulgarian32, label: "+359" },
	// { value: "+421", image: images.slovak32, label: "+421" },
];
const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
	control: {
		// width: rem(80),
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: "0.5rem",
		// padding: `${theme.spacing.xs} ${theme.spacing.md}`,
		padding: `0.25rem 0.5rem`,
		borderRadius: theme.radius.md,
		border: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
			}`,
		transition: "background-color 150ms ease",
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[opened ? 5 : 6]
				: opened
					? theme.colors.gray[0]
					: theme.white,

		"&:hover": {
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[0],
		},
	},

	label: {
		fontWeight: 500,
		fontSize: theme.fontSizes.sm,
	},

	icon: {
		transition: "transform 150ms ease",
		transform: opened ? "rotate(180deg)" : "rotate(0deg)",
	},
}));
export enum ETypeSelection {
	TIMEZONE = "Timezone",
	LANGUAGE = "CompanyLanguage",
	CURRENCY = "Currency",
	COUNTRY = "Country",
	PREFIX = "Prefix",
}
interface Props {
	value: any;
	setValue: (value: string) => void;
	type: ETypeSelection;
}
function TimezoneAndName({ value, setValue, type }: Props) {
	const [opened, setOpened] = useState(false);
	const { classes } = useStyles({ opened });
	const [selected, setSelected] = useState(country[0]);
	const [dataToDisplay, setDataToDisplay] = useState(country);
	useEffect(() => {
		switch (type) {
			case ETypeSelection.TIMEZONE:
				setDataToDisplay(timezone);
				setSelected(timezone.find((item) => item.value === value) || timezone[0]);
				break;
			case ETypeSelection.LANGUAGE:
				setDataToDisplay(companyLanguage);
				setSelected(companyLanguage.find((item) => item.value === value) || companyLanguage[0]);
				break;
			case ETypeSelection.CURRENCY:
				setDataToDisplay(currency);
				setSelected(currency.find((item) => item.value === value) || currency[0]);
				break;
			case ETypeSelection.COUNTRY:
				setDataToDisplay(country);
				setSelected(country.find((item) => item.value === value) || country[0]);
				break;
			case ETypeSelection.PREFIX:
				setDataToDisplay(countryPrefix);
				setSelected(countryPrefix.find((item) => item.value === value) || countryPrefix[0]);
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, value]);

	// useEffect(() => {
	// 	setValue(selected.value);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [selected]);

	const items = dataToDisplay.map((item) => (
		<Menu.Item
			icon={<Image src={item.image} width={25} height={25} />}
			onClick={() => setValue(item.value)}
			key={item.value}
		>
			{item.label}
		</Menu.Item>
	));

	return (
		<Menu
			onOpen={() => setOpened(true)}
			onClose={() => setOpened(false)}
			radius="md"
			width="target"
			withinPortal
			styles={{
				dropdown: {
					//@ts-ignore
					zIndex: "99999999 !important",
				},
				item: {
					width: "100%",
					padding: `0.25rem 0.5rem`,
				},
			}}
		>
			<Menu.Target>
				<UnstyledButton className={classes.control}>
					<Group spacing="xs">
						<Image src={selected.image} width={25} height={25} />
						<span className={classes.label}>{selected.label}</span>
					</Group>
					<IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
				</UnstyledButton>
			</Menu.Target>
			<Menu.Dropdown>{items}</Menu.Dropdown>
		</Menu>
	);
}

export default TimezoneAndName;
