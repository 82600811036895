import { UserType } from "@hooks/users/useEditUser";
import { Button, Flex, Modal, Select, TextInput } from "@mantine/core";
import { Dispatch, SetStateAction } from "react";
import { IEditUser, IRegisteredUser, modalInitialValues } from "../Register";
import EqualGapContainer from "./EqualGapContainer";



export const getRolesBooleans = (role: UserType) => {
	switch (role) {
		case UserType.Driver:
			return {
				isAdmin: false,
				isSuperAdmin: false,
				isDriver: true,
			};
		case UserType.Sediu:
			return {
				isAdmin: true,
				isSuperAdmin: false,
				isDriver: false,
			};
		case UserType.SuperAdmin:
			return {
				isAdmin: true,
				isSuperAdmin: true,
				isDriver: false,
			};
		case UserType.PickupPoint:
			return {
				isAdmin: true,
				isSuperAdmin: false,
				isDriver: false,
			};

		default:
			return {
				isAdmin: false,
				isSuperAdmin: false,
				isDriver: false,
			};
	}
}
const roles = [
	{
		label: "Super Admin",
		value: "isSuperAdmin",
	},
	{
		label: "Sediu",
		value: "isAdmin",
	},
	{
		label: "Sofer",
		value: "isDriver",
	},
	{
		label: "Pickup Point",
		value: "isPickupPoint",
	},
];

const getRoles = (onlyPickupPoint: boolean) => {
	if (onlyPickupPoint) {
		return roles.filter((role) => role.value === "isPickupPoint");
	}
	return roles.filter((role) => role.value !== "isPickupPoint");

}

interface IAddUserModal {
	opened: boolean;
	close: () => void;
	addOrEditUser: (user: IRegisteredUser, edit: boolean, editIndex: number | null) => void;
	modalValues: IEditUser;
	setModalValues: Dispatch<SetStateAction<IEditUser>>;
	isEditMode: boolean;
	isLoading?: boolean;
	onlyPickupPoint?: boolean;
	isEditAlreadyRegistered?: boolean;
}


const getSuffixedUsernameAndUsername = (username: string) => {
	// Check if '@' is present in the username
	const atIndex = username.indexOf("@");

	if (atIndex !== -1) {
		// If '@' is found, slice the username accordingly
		const suffix = username.slice(atIndex);
		const usernameWithoutSuffix = username.slice(0, atIndex);
		return { suffix, usernameWithoutSuffix };
	} else {
		// If '@' is not found, return the whole username as usernameWithoutSuffix and an empty string as suffix
		return { suffix: "", usernameWithoutSuffix: username };
	}
}

export default function AddUserModal({
	opened,
	close,
	addOrEditUser,
	modalValues,
	setModalValues,
	isEditMode,
	isLoading,
	onlyPickupPoint = false,
	isEditAlreadyRegistered = false,
}: IAddUserModal) {
	const fillUserData = (name: string, value: string) => {
		//if username, concat with suffix
		if (name === "username" && modalValues.username) {
			const { suffix } = getSuffixedUsernameAndUsername(modalValues.username);
			setModalValues((prev) => ({
				...prev,
				[name]: value + suffix,
			}));
			return;
		}
		setModalValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const setRole = (role: string) => {
		const roleObject = roles.find((item) => item.value === role);
		const labelAsRole = roleObject?.label.toLowerCase().replace(" ", "-");
		const rolesBooleans = getRolesBooleans(labelAsRole as UserType);
		setModalValues((prev) => ({ ...prev, role: labelAsRole as UserType, ...rolesBooleans }));
	};

	return (
		<Modal centered size="auto" title="Adauga un user nou" opened={opened} onClose={() => close()}>
			<EqualGapContainer gap="16px">
				<TextInput
					defaultValue={modalValues.name}
					onChange={(e) => fillUserData(e.target.name, e.target.value)}
					name="name"
					w={250}
					size="sm"
					required
					label="Nume"
				/>
				<TextInput
					defaultValue={modalValues.phone}
					onChange={(e) => fillUserData(e.target.name, e.target.value)}
					name="phone"
					w={250}
					size="sm"
					required
					label="Numar de telefon"
				/>
				{
					isEditAlreadyRegistered && modalValues.username && (
						<TextInput
							defaultValue={getSuffixedUsernameAndUsername(modalValues.username).usernameWithoutSuffix}
							onChange={(e) => fillUserData(e.target.name, e.target.value)}
							name="username"
							w={250}
							size="sm"
							label="Username"
							styles={(theme) => ({
								rightSection: {
									width: "50%",
									backgroundColor: theme.colors.gray[6],
									border: "1px solid " + theme.colors.gray[4],
								}
							})}
							rightSection={
								<div style={{
									paddingLeft: "5px",
									border: "none",
									color: "black",
									width: "100%",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis"

								}}>
									{getSuffixedUsernameAndUsername(modalValues.username).suffix}
								</div>
							}
						/>
					)
				}
				{
					!isEditAlreadyRegistered && (
						<Select
							defaultValue={modalValues.role}
							onChange={(e) => setRole(e as string)}
							name="role"
							w={250}
							data={getRoles(onlyPickupPoint)}
							size="sm"
							required
							label="Rol"
						/>
					)
				}

				<TextInput
					defaultValue={modalValues.password}
					onChange={(e) => fillUserData(e.target.name, e.target.value)}
					name="password"
					w={250}
					size="sm"
					label="Parola"
					description="Parola e necesara pentru a putea accesa aplicatia"
				/>
				<Flex justify="flex-end">
					<Button
						onClick={() => {
							addOrEditUser(modalValues, isEditMode, modalValues.index);
							close();
							setModalValues(modalInitialValues);
						}}
						disabled={!modalValues.name || !modalValues.phone || !modalValues.role}
						loading={isLoading}
					>
						{isEditMode ? "Editeaza" : "Adauga"}
					</Button>
				</Flex>
			</EqualGapContainer>
		</Modal>
	);
}
