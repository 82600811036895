import { Flex, MultiSelect, TextInput } from "@mantine/core";
import EqualGapContainer from "./EqualGapContainer";
import SectionLabel from "./SectionLabel";

interface IAdministratorData {
	fillData: (name: string, value: string) => void;
	selectModules: (modules: string[]) => void;
}

const modules = ["Carpet", "Horeca", "Haine"];

export default function AdministratorData({ fillData, selectModules }: IAdministratorData) {
	return (
		<div>
			<EqualGapContainer gap="26px">
				<SectionLabel
					label="Administrator data"
					alert="Please add at least one module and the administrator's name and BI, mandatory"
				/>
				<EqualGapContainer gap="16px">
					<Flex gap={16}>
						<TextInput
							onChange={(e) => fillData(e.target.name, e.target.value)}
							name="administratorName"
							w="100%"
							size="sm"
							required
							label="Administrator name"
						/>
						<TextInput
							onChange={(e) => fillData(e.target.name, e.target.value)}
							name="administratorBi"
							w="100%"
							size="sm"
							required
							label="Administrator BI"
						/>
					</Flex>
					<MultiSelect
						onChange={(e) => selectModules(e.map((item) => item.toLowerCase()))}
						data={modules}
						w="100%"
						size="sm"
						required
						label="Module"
					/>
					<TextInput
						name="clientReferrralForm"
						onChange={(e) => fillData(e.target.name, e.target.value)}
						w="100%"
						size="sm"
						label="Referral"
						defaultValue="Zarpet-Website"
					/>
				</EqualGapContainer>
			</EqualGapContainer>
		</div>
	);
}
