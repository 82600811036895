import bulgarian16 from "./bg-16.png";
import bulgarian32 from "./bg-32.png";
import german16 from "./de-16.png";
import german32 from "./de-32.png";
import english16 from "./en-16.png";
import english32 from "./en-32.png";
import french16 from "./fr-16.png";
import french32 from "./fr-32.png";
import hungarian16 from "./hu-16.png";
import hungarian32 from "./hu-32.png";
import moldova16 from "./md-16.png";
import moldova32 from "./md-32.png";
import dutch16 from "./nl-16.png";
import dutch32 from "./nl-32.png";
import polish16 from "./pl-16.png";
import polish32 from "./pl-32.png";
import romanian16 from "./ro-16.png";
import romanian32 from "./ro-32.png";
import slovak16 from "./sk-16.png";
import slovak32 from "./sk-32.png";
import turkish16 from "./tr-16.png";
import turkish32 from "./tr-32.png";

const images = {
	english16,
	english32,
	hungarian16,
	hungarian32,
	romanian16,
	romanian32,
	polish16,
	polish32,
	dutch16,
	dutch32,
	french16,
	french32,
	german16,
	german32,
	turkish16,
	turkish32,
	bulgarian16,
	bulgarian32,
	slovak16,
	slovak32,
	moldova16,
	moldova32,
};

export default images;
